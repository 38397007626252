@font-face {
  font-family: 'SamsungOne-Regular';
  src: local('SamsungOne-Regular'), url(./fonts/SamsungOne-400.woff2) format('woff2')
}
@font-face {
  font-family: 'SamsungOne-Bold';
  src: local('SamsungOne-Bold'), url(./fonts/SamsungOne-700.woff2) format('woff2')
}
@font-face {
  font-family: 'SamsungSharpSans-Bold';
  src: local('SamsungSharpSans-Bold'), url(./fonts/SamsungSharpSans-Bold.woff2) format('woff2')
}
@font-face {
  font-family: 'SamsungSharpSans-Medium';
  src: local('SamsungSharpSans-Medium'), url(./fonts/SamsungSharpSans-Medium.woff2) format('woff2')
}

body {
  margin: 0;
  font-family: 'SamsungOne-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
